.sidebar {
  z-index: 100;
  padding: 3rem 0.5rem 6rem;
  box-shadow: inset -0.25rem -0.25rem 0 rgba(0, 0, 0, .1);
}

#sidebar-wrapper{
  width: 100vw;
  
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

.fixed-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}