
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$enable-gradients: true;
$enable-pointer-cursor-for-buttons: false;

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";

// 4. Include any optional Bootstrap components as you like
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/grid";

// 5. Add additional custom code here

/* Common element selectors */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* we generally want lists to be left justified */
ul {
  text-align: left;
}

/* Common Class selectors */
.fa-bg-light {
  background-color: #fffefd;
}

.page-header-bar {
  background-color: $gray-300;
  text-align: center;
  padding: 20px;
}

.fa-full-page-height {
  min-height: 100vh; // Override height to Viewport Height, if height is less. Otherwise use full height
}

.page-wrapper {
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
}


@media (min-width: 1400px) {
  /* define a custom xxl container, to extend Bootstrap */
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1340px;
  }
}

// @media (min-width: 70em) {
//   /* Increase the global font size on larger screens or windows
//      for better readability */
//   body {
//     font-size: 120%;
//   }
// }

@media screen and (max-width: 576px) {
  /* primitive way to shrink images on sm screens */
  .img-sm-resize {
    width: 100%;
  }
} 